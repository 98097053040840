import { useEffect, useRef, useState } from 'react'

import { calculateCountdown, TimeLeft } from '../lib/countdown'

import styles from './Countdown.module.css'

const addLeadingZeros = (value: number): string => {
  let valueString = String(value)
  while (valueString.length < 2) {
    valueString = '0' + value
  }
  return valueString
}
const Countdown: React.FC<{ date: string; title: string }> = ({ date, title }) => {
  const newDate = new Date(Date.parse(date))
  console.log(newDate)
  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(calculateCountdown(newDate))
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    timer.current = setTimeout(() => setTimeLeft(calculateCountdown(newDate)), 1000)
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [newDate])

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {timeLeft && (
        <div className={styles.countdown}>
          <span className={styles.countdownCol}>
            <span className={styles.countdownColElement}>
              <strong>{addLeadingZeros(timeLeft.days)}</strong>
            </span>
            <span>{timeLeft.days === 1 ? 'Day' : 'Days'}</span>
          </span>

          <span className={styles.countdownCol}>
            <span className={styles.countdownColElement}>
              <strong>{addLeadingZeros(timeLeft.hours)}</strong>
            </span>
            <span>Hours</span>
          </span>

          <span className={styles.countdownCol}>
            <span className={styles.countdownColElement}>
              <strong>{addLeadingZeros(timeLeft.min)}</strong>
            </span>
            <span>Min</span>
          </span>

          <span className={styles.countdownCol}>
            <span className={styles.countdownColElement}>
              <strong>{addLeadingZeros(timeLeft.sec)}</strong>
            </span>
            <span>Sec</span>
          </span>
        </div>
      )}
    </div>
  )
}
export default Countdown
