import React from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'

import Countdown from './pages/Countdown'

function App() {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Routes>
      <Route
        path='/countdown/'
        element={
          <Countdown
            date={searchParams.get('date') as string}
            title={searchParams.get('title') as string}
          />
        }
      />
    </Routes>
  )
}

export default App
